import Link from "next/link";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import ProductCnpjs from "@2ndmarket/components/src/helper/ProductCnpjs";
import { SecondMarketSocials } from "@2ndmarket/components/src/helper/ProductSocialNetworks";

import {
  FooterContent,
  SocialMediaContainer,
  TextsContainer,
  Box,
  BrandLink,
  FooterContainer,
} from "./styles";

const Footer = () => {
  const { locale = 'pt' } = useRouter();
  const currentYear = new Date().getFullYear();

  const mockFooter = {
      pt: {
        term: "Termos de Uso",
        help: "Ajuda",
        copy: "Todos direitos reservados",
        title: "Confira todo o nosso ecossitema:",
      },
      en: {
        term: "Terms of Use",
        help: "Help",
        copy: "All rights reserved",
        title: "Check out our entire ecosystem:",
      },
    }

  const footerLocale = mockFooter[locale];

  return (
    <FooterContainer>
      <FooterContent>
        <SocialMediaContainer>
          <Link href="/">
            <a rel="noreferer">
              <img
                loading="lazy"
                src="/logo.svg"
                alt="2ndmarket"
                width={200}
                height={29}
              />
            </a>
          </Link>
          <div>
            <Link href={SecondMarketSocials.FACEBOOK}>
              <a target="_blank" rel="noreferer">
                <img loading="lazy" src="/facebook-footer.svg" alt="facebook" />
              </a>
            </Link>
            <Link href={SecondMarketSocials.LINKEDIN}>
              <a target="_blank" rel="noreferer">
                <img loading="lazy" src="/linkedin-footer.svg" alt="linkedin" />
              </a>
            </Link>
            <Link href={SecondMarketSocials.INSTAGRAM}>
              <a target="_blank" rel="noreferer">
                <img
                  loading="lazy"
                  src="/instagram-footer.svg"
                  alt="instagram"
                />
              </a>
            </Link>
          </div>
        </SocialMediaContainer>

        <Box>
          <p>{footerLocale.title}</p>
          <Link href={ProductUrls.BANKAI}>
            <a target="_blank" rel="noreferrer">
              Bank.ai
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.BRAEX}>
            <a target="_blank" rel="noreferrer">
              Bra.ex
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.CUSTON}>
            <a target="_blank" rel="noreferrer">
              Cust.on
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.TOKENAIM}>
            <a target="_blank" rel="noreferrer">
              Token.aim
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
        </Box>
      </FooterContent>
      <TextsContainer>
        <p className="links">
          <Link href={ProductUrls.TERMS_AND_CONDITIONS}>
            <a target="_blank" rel="noreferrer">
              {footerLocale.term}
            </a>
          </Link>
          <Link href={ProductUrls.SUPPORT}>
            <a target="_blank" rel="noreferrer">
              {footerLocale.help}
            </a>
          </Link>
        </p>
        <p>
          © {currentYear}{" "}
          <BrandLink href={ProductUrls.SECOND_MARKET}>Second Market</BrandLink>{" "}
          - {footerLocale.copy}
        </p>
        <p>CNPJ {ProductCnpjs.SECOND_MARKET}</p>
      </TextsContainer>

      <div className="squares">
        <img src="../footer-squares.svg" alt="" width={279} height={174} />
      </div>
    </FooterContainer>
  );
};

export default Footer;
