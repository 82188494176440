import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: #fbfbff;
  padding: 80px 0 52px 6vw;
  position: relative;

  .squares {
    position: absolute;
    bottom: 75px;
    right: 10%;
  }

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    padding: 50px 4vw 34px 4vw;

    .squares {
      display: none;
    }
  }
`;

export const FooterContent = styled.div`
  margin-top: 16px;
  background-color: #fbfbff;
  display: flex;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  a > img {
    margin: 0;
  }

  div {
    display: flex;
    gap: 40px;
  }

  @media only screen and (max-width: 1025px) {
    gap: 28px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    a {
      margin: 0;
    }

    div {
      gap: 25px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-left: 260px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #4150e6;
    margin: 0 0 10px 0;
  }

  a {
    font-size: 14px;
    font-weight: 500;

    color: #292d37;
    transition: color 0.25s;
    text-decoration-line: none;

    &:hover {
      color: #4150e6;
    }
  }

  div {
    display: flex;
    gap: 40px;
    margin-top: 45px;

    @media only screen and (max-width: 1025px) {
      margin: 40px 0 35px 0;
    }
  }

  @media only screen and (max-width: 1025px) {
    margin: 39px 0;

    img {
      width: fit-content;
    }
  }
`;

export const TextsContainer = styled.div`
  color: #535668;

  .links {
    a {
      color: #535668;
      padding: 0 7px;
      border-left: 1px solid #535668;
      transition: 0.5s;

      :first-child {
        padding: 0 7px 0 0;
        border: none;
      }

      :hover {
        color: #4150e6;
        transition: 0.5s;
        text-decoration: none;
      }
    }

    @media only screen and (max-width: 1025px) {
      margin-bottom: 20px;
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #535668;
    margin: 0;
  }
`;

export const SingleLink = styled.a`
  color: #4150e6;
  margin: 0px 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const BrandLink = styled.a`
  color: #4150e6;

  &:hover {
    text-decoration: underline;
  }
`;
