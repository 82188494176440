import styled, { css } from "styled-components";

const styles = css`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 15px 45px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #313ee2;
  -webkit-appearance: none;
  background: #313ee2;
  box-shadow: 0px 10px 25px rgba(65, 80, 230, 0.2);
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
  width: fit-content;

  :hover {
    background: #1a2489;
  }

  &:active {
    background: white;
  }
`;

export const Container = styled.input.attrs((props) => ({
  type: "submit",
}))`
  ${styles}
`;

export const ContainerLink = styled.a`
  ${styles}

  &.btn-header {
    width: 150px;
    padding: 14px 25px;
  }
`;
