import React from "react";

import { Container, ContainerLink } from "./styles";

function ButtonPattern({ children, anchor, newPage, className }) {
  return (
    <>
      {anchor ? (
        <ContainerLink
          href={anchor}
          className={className}
          target={newPage ? "_blank" : "_parent"}
        >
          {children}
        </ContainerLink>
      ) : (
        <Container minWidth="150px" marginLeft="auto" value={children} />
      )}
    </>
  );
}

export default ButtonPattern;
