import { Parent, Placeholder, Input } from "./styles";

import InputMask from "react-input-mask";

function BaseInput({ type, name, placeholder, changeValue, mask, value }) {
  if (mask) {
    return (
      <Parent>
        <InputMask
          mask={mask}
          onChange={(e) => changeValue(e.target.value)}
          value={value}
          maskChar=" "
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              name={name}
              id={type}
              placeholder=" "
              required="required"
            />
          )}
        </InputMask>
        <Placeholder htmlFor={type}>{placeholder}</Placeholder>
      </Parent>
    );
  } else {
    return (
      <Parent>
        <Input
          type={type}
          name={name}
          id={type}
          placeholder=" "
          required="required"
          onChange={(e) => changeValue(e.target.value)}
          value={value}
        />
        <Placeholder htmlFor={type}>{placeholder}</Placeholder>
      </Parent>
    );
  }
}

export default BaseInput;
