import { useState } from "react";
import { useRouter } from "next/router";

import { ButtonPattern } from "..";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import {
  HeaderContent,
  Left,
  ContainerEnd,
  LogoContainer,
  ContainerMenuMobile,
  LogoContainerMobile,
  BoxLoginMobile,
  MenuContainer,
} from "./styles";
import Link from "next/link";

const Header = () => {
  const { locale = "pt" } = useRouter();

  const mockMenuLocale = {
    pt: {
      quemsomos: "Quem Somos",
      ativo: "Ativos Digitais",
      prod: "Produtos",
      result: "Resultados",
      blog: "Blog",
      contato: "Contato",
      login: "Login",
      cadastro: "Cadastre-se",
      localemenu: "Idioma",
      path: "/pt",
    },
    en: {
      quemsomos: "Who we are",
      ativo: "Digital Assets",
      prod: "Products",
      result: "Results",
      blog: "Blog",
      contato: "Contact",
      login: "Login",
      cadastro: "Register",
      localemenu: "Language",
      path: "/en",
    },
  };

  const menuLocale = mockMenuLocale[locale];
  const [menuMobileShow, setMenuMobileShow] = useState(false);

  return (
    <>
      <HeaderContent>
        <Left>
          <LogoContainer>
            <a href={`${menuLocale.path}`}>
              <img
                src="/logo.svg"
                loading="lazy"
                alt="2nd MKT"
                width={200}
                height={29}
              />
            </a>
            <button
              type="button"
              id="menu-mobile"
              aria-label="mobile"
              onClick={() => setMenuMobileShow(!menuMobileShow)}
            >
              <img
                src={
                  menuMobileShow ? "/close-icon.svg" : "/hamburguer-icon.svg"
                }
                loading="lazy"
                height={29}
                width={31}
                alt=""
              />
            </button>
          </LogoContainer>
          <nav>
            <ul>
              <li>
                <a href={`${menuLocale.path}/#quem-somos`}>
                  {menuLocale.quemsomos}
                </a>
              </li>
              <li>
                <a href={`${menuLocale.path}/#ativos-digitais`}>
                  {menuLocale.ativo}
                </a>
              </li>
              <li>
                <a href={`${menuLocale.path}/#produtos`}>{menuLocale.prod}</a>
              </li>
              <li>
                <a href={`${menuLocale.path}/#resultados`}>
                  {menuLocale.result}
                </a>
              </li>
              <li>
                <a href={ProductUrls.BLOG} target="_blank">
                  {menuLocale.blog}{" "}
                  <img
                    alt=""
                    width={12}
                    height={12}
                    loading="lazy"
                    src="/blog-icon.svg"
                  />
                </a>
              </li>
              <li>
                <a href={`${menuLocale.path}/#contato`}>{menuLocale.contato}</a>
              </li>
            </ul>
          </nav>
        </Left>

        <ContainerEnd>
          <a href={ProductUrls.AUTH_LOGIN} target="_blank">
            {menuLocale.login}
          </a>
          <ButtonPattern
            anchor={ProductUrls.AUTH_REGISTER}
            className="btn-header"
            newPage
          >
            {menuLocale.cadastro}
          </ButtonPattern>
          <span>
            <a
              href={`${locale === "pt" ? "#" : "/pt"}`}
              className={`${locale === "pt" ? "active" : ""}`}
            >
              PT
            </a>
            <a
              href={`${locale === "en" ? "#" : "/en"}`}
              className={`${locale === "en" ? "active" : ""}`}
            >
              EN
            </a>
          </span>
        </ContainerEnd>
      </HeaderContent>

      {menuMobileShow && (
        <ContainerMenuMobile>
          <LogoContainerMobile>
            <span>&nbsp;</span>
            <button type="button" onClick={() => setMenuMobileShow(false)}>
              <img
                width="14.5"
                height="14.5"
                loading="lazy"
                src="/close-icon.svg"
              />
            </button>
          </LogoContainerMobile>
          <MenuContainer>
            <ul>
              <li>
                <a
                  onClick={() => setMenuMobileShow(false)}
                  href={`${menuLocale.path}/#quem-somos`}
                >
                  {menuLocale.quemsomos}
                </a>
              </li>
              <li>
                <a
                  onClick={() => setMenuMobileShow(false)}
                  href={`${menuLocale.path}/#ativos-digitais`}
                >
                  {menuLocale.ativo}
                </a>
              </li>
              <li>
                <a
                  onClick={() => setMenuMobileShow(false)}
                  href={`${menuLocale.path}/#produtos`}
                >
                  {menuLocale.prod}
                </a>
              </li>
              <li>
                <a
                  onClick={() => setMenuMobileShow(false)}
                  href={`${menuLocale.path}/#resultados`}
                >
                  {menuLocale.result}
                </a>
              </li>
              <li>
                <a
                  onClick={() => setMenuMobileShow(false)}
                  href={ProductUrls.BLOG}
                >
                  {menuLocale.blog}{" "}
                  <img loading="lazy" src="/blog-icon.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  onClick={() => setMenuMobileShow(false)}
                  href={`${menuLocale.path}/#contato`}
                >
                  {menuLocale.contato}
                </a>
              </li>
              <li className="locale">
                <p>{menuLocale.localemenu}</p>
              </li>
            </ul>
            <div className={`language`}>
              <span className={`${locale === "pt" ? "active" : ""}`}>
                <a href="/pt">PT</a>
              </span>
              <span className={`${locale === "en" ? "active" : ""}`}>
                <a href="/en">EN</a>
              </span>
            </div>
            <BoxLoginMobile>
              <a
                href={ProductUrls.AUTH_LOGIN}
                className="btn-login"
                target="_blank"
              >
                {menuLocale.login}
              </a>
              <a
                href={ProductUrls.AUTH_REGISTER}
                className="btn-cadastro"
                target="_blank"
              >
                {menuLocale.cadastro}
              </a>
            </BoxLoginMobile>
          </MenuContainer>
        </ContainerMenuMobile>
      )}
    </>
  );
};

export default Header;
