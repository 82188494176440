import styled from "styled-components";

export const Parent = styled.div`
  font-family: Montserrat, sans-serif;
  margin-bottom: 9px;
  border-radius: 2px;
  height: 56px;
  position: relative;

  input:not(:placeholder-shown) + label {
    font-weight: 400;
    font-size: 12px;
    top: 12px;
  }

  input:focus + label {
    font-weight: 400;
    color: #4150e6;
    font-size: 12px;
    top: 12px;
  }
`;

export const Input = styled.input`
  font-family: Montserrat, sans-serif;
  color: #5a6473;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid #f0f0f9;
  height: 100%;
  padding: 25px 15px 9px 15px;
  transition: border-color 0.25s ease;
  font-size: 16px;

  &:focus {
    border-color: #4150e6;
    outline: none;
  }
`;

export const Placeholder = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #8c96a0;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  transition: all 0.25s ease;
`;
