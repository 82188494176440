import styled from "styled-components";

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 3vw;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: #fbfbff;

  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 1440px) {
    padding: 15px 2vw;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 28px 4vw;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nav ul {
    display: flex;
    list-style-type: none;

    @media only screen and (max-width: 1200px) {
      display: none;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      margin-right: 30px;
      color: #292d37;
      text-decoration: none;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: #4150e6;
      }
    }
  }
`;

export const Left = styled.div`
  display: flex;
  gap: 60px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background: transparent;
    width: 31px;

    @media only screen and (min-width: 1200px) {
      display: none;
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 1200px) {
    gap: 100px;
  }
`;

export const ContainerEnd = styled.div`
  gap: 30px;
  display: flex;
  align-items: center;

  span {
    gap: 15px;
    display: flex;
    margin-right: 37px;
    text-decoration: none;
    transition: color 0.25s ease-in-out;

    a {
      color: #b4b9c7;
      font-size: 12px;
      font-weight: 500;

      &.active {
        color: #292d37;
      }

      &:hover {
        color: #4150e6;
      }
    }
  }

  a {
    font-size: 14px;
    font-weight: 500;

    &:not(.btn-header) {

      &:hover {
        color: #4150e6;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    span {
      margin-right: 1px;
    }
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const ContainerMenuMobile = styled.div`
  background-color: #fff;
  height: 101%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;

  button {
    background: transparent;
    box-shadow: 0px 10px 25px rgba(70, 220, 175, 0.1);
    border: none;
  }

  ul {
    list-style-type: none;
    margin: 0 4vw;
    padding: 0;

    li {
      margin-bottom: 20px;

      a {
        font-size: 14px;

        color: #292d37;
        text-decoration: none;
      }
    }
  }
`;

export const LogoContainerMobile = styled.div`
  display: flex;
  margin: 28px 4vw 15px 4vw;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0;
    display: flex;
    align-items: center;

    img {
      color: #292d37;
    }
  }
`;

export const BoxLoginMobile = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 0 4vw;
  box-shadow: none;

  position: absolute;
  bottom: 0;

  width: 100%;

  a {
    width: 100%;
    text-align: center;

    margin-bottom: 10px;
    box-shadow: none;
    padding: 20px;

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    &.btn-login {
      background-color: #e5e9ee;
      color: #313ee2;
    }

    &.btn-cadastro {
      background-color: #313ee2;
      color: #e5e9ee;
    }
  }

  span {
  }

  /* @media only screen and (max-width: 1025px) {
    a {
      background-color: #f0f0f9;
      box-shadow: none;
      color: #4150e6;
      display: block;
      width: 100%;
    }
  } */
`;

export const MenuContainer = styled.div`
  height: 75%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  @media only screen and (max-width: 1025px) {
    gap: 20px;

    ul {
      li {
        border-bottom: 1px solid #e5e9ee;
        margin-bottom: 10px;

        a {
          line-height: 45px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;

          color: #b4b9c7;
        }
      }

      > .locale {
        border-bottom: none;
        margin-bottom: 0;

        p {
          margin-bottom: 0;
        }
      }
    }

    .language {
      gap: 30px;
      display: flex;
      justify-content: space-between;

      padding: 0 4vw;

      span {
        border: 1px solid #e5e9ee;
        background-color: #fff;
        border-radius: 2px;
        padding: 3vw 17.5vw;

        a {
          color: #292d37;
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
        }
      }

      > .active {
        background-color: #e5e9ee;
      }
    }
  }
`;
